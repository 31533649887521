/* eslint-disable react/no-danger */

import React from 'react';
import { string, shape } from 'prop-types';

import { escape } from 'html-escaper';
import Message from '@andes/message';

import Link from '../../components-v2/link';
import useZrpDisclaimer from './use-zrp-disclaimer';

const namespace = 'ui-search-zrp-disclaimer';

const renderZrpMessage = (zrp_query_text, query) => {
  if (query) {
    return (
      <>
        {zrp_query_text}
        <span className={`${namespace}__query`}>{` ${query} `}</span>
      </>
    );
  }

  return <span className={`${namespace}__full-message`} dangerouslySetInnerHTML={{ __html: escape(zrp_query_text) }} />;
};

const ZrpDisclaimer = ({ zrp_query_text, className, query, other_sellers_text, link }) => (
  <Message className={`${namespace} ${className}`} closable defaultVisible hierarchy="loud">
    <div>
      {renderZrpMessage(zrp_query_text, query)}
      {other_sellers_text && `${other_sellers_text} `}
      {link && (
        <Link href={link.url} className={`${namespace}__link`} isInternal={false} title={link.text}>
          {link.text}
        </Link>
      )}
    </div>
  </Message>
);

ZrpDisclaimer.propTypes = {
  className: string.isRequired,
  link: shape({
    url: string.isRequired,
    text: string.isRequired,
  }),
  other_sellers_text: string,
  query: string,
  zrp_query_text: string.isRequired,
};

ZrpDisclaimer.defaultProps = {
  link: null,
  other_sellers_text: null,
  query: null,
};

const ZrpDisclaimerContainer = () => {
  const zrp_disclaimer = useZrpDisclaimer();

  return zrp_disclaimer ? <ZrpDisclaimer {...zrp_disclaimer} /> : null;
};

export default ZrpDisclaimerContainer;
export { ZrpDisclaimer };
